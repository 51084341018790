import React from 'react';

const Servicebox = (props) => {
  return (
    <div className='a-box'>
        <div className='a-b-text'>
            <h2>{props.title}</h2>
            <p> {props.para}</p>
        </div>
        
    </div>
  )
}

export default Servicebox