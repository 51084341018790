import React from 'react'
import aboutimage from '../images/about.png';
import business from '../images/business.png';
import strategic from '../images/strategic.png';
import dddm from '../images/dd.jpg';
import cl from '../images/cl.jpg';
import cm from '../images/cm.jpg';
import Portfoliodata from '../Components/Portfoliodata';

const Portfolio = () => {
  return (
    <div id='portfolioid'>
      <div className='portfolio'>
        <h1> Portfolio</h1>
        <Portfoliodata
            classname="first-des"
            heading="Project Management"
            text1="- Proven expertise in orchestrating successful projects from initiation to completion."
            text2="- Strong leadership in defining project scope, goals, and deliverables."
            text3="- Demonstrated ability to manage resources, timelines, and budgets effectively."
            text4="- Exceptional risk management skills, ensuring project success in dynamic environments."
            text5="- Proficient in utilizing project management tools for seamless collaboration and tracking."
            img1={aboutimage}
        />

        <Portfoliodata
            classname="first-des-rev"
            heading="Business Analysis"
            text1="- Extensive experience in analyzing business processes and identifying improvement opportunities."
            text2="- Proficient in gathering and documenting business requirements to align with organizational objectives."
            text3="- Skilled in conducting stakeholder interviews to understand needs and expectations."
            text4="- Expertise in creating comprehensive business analysis documentation, including use cases and process flows."
            text5="- Proven track record of translating business needs into actionable solutions."
            img1={business}
        />  

        <Portfoliodata
            classname="first-des"
            heading="Strategic Planning"
            text1="- Adept at developing and implementing strategic plans aligned with business goals."
            text2="- Comprehensive understanding of industry trends and their impact on project and business strategies."
            text3="- Strategic foresight in anticipating challenges and opportunities, enabling proactive decision-making."
            text4="- Collaboration with key stakeholders to develop comprehensive strategic roadmaps, fostering alignment and buy-in across departments."
            text5="- Continuous evaluation and refinement of strategic plans based on evolving market conditions and internal capabilities, optimizing performance and maximizing outcomes."
            img1={strategic}
        />
        
        <Portfoliodata
            classname="first-des-rev"
            heading="Data-Driven Decision-Making"
            text1="- Utilize data analysis techniques to extract meaningful insights for informed decision-making."
            text2="- Expertise in interpreting and presenting data to support strategic business choices."
            text3="- Implement data-driven strategies to enhance operational efficiency and optimize business processes."
            text4="- Collaborate cross-functionally to identify opportunities for process optimization and innovation through data-driven strategies, driving continuous improvement and competitive advantage."
            text5="- Develop and maintain robust data analytics frameworks and systems, ensuring the accuracy, reliability, and security of data sources and outputs."
            img1={dddm}
        />

      <Portfoliodata
            classname="first-des"
            heading="Collaborative Leadership"
            text1="- Foster a collaborative environment by effectively communicating with cross-functional teams."
            text2="- Facilitate workshops and meetings to encourage stakeholder engagement and consensus."
            text3="- Demonstrate adeptness in building consensus and alignment among team members, leveraging strong interpersonal skills and strategic diplomacy to bridge gaps and reconcile divergent viewpoints."
            text5="- Lead by example, embodying the values of inclusivity, transparency, and accountability, while inspiring and motivating teams to strive for excellence and achieve common project goals."
            img1={cl}
        />

      <Portfoliodata
            classname="first-des-rev"
            heading="Continuous Improvement"
            text1="- Embrace a culture of continuous learning and development, actively seeking out opportunities to enhance skills and knowledge through professional development, training programs, and industry certifications."
            text3="- Establish structured feedback mechanisms, such as regular performance reviews, customer surveys, and employee suggestion programs, to solicit input from stakeholders at all levels and leverage insights for iterative refinement and enhancement."
            text5="- Implement agile methodologies and lean principles to streamline workflows, minimize waste, and increase efficiency in project management and business processes, fostering a culture of adaptability and responsiveness to changing market dynamics."
            img1={cm}
        />

      </div>
    </div>
  )
}

export default Portfolio