import { Component } from "react";

class Portfoliodata extends Component {
    render(){
        return(
            <div className={this.props.classname}>
                <div className="des-text">
                    <h2>{this.props.heading}</h2>
                    <p>{this.props.text1}</p>
                    <p>{this.props.text2}</p>
                    <p>{this.props.text3}</p>
                    <p>{this.props.text4}</p>
                    <p>{this.props.text5}</p>
                </div>
                <div className="prot-img">
                    <img alt="img" src={this.props.img1} />
                </div>
            </div>
        )

    }

}

export default Portfoliodata