import React, {useState} from 'react';
import axios from 'axios';

const Contact = () => {
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    email: '',
    message: '',
    file: null
  });

  const [buttonClicked, setButtonClicked] = useState(false);

  const { fname, lname, email, message, file } = formData;

  const changeHandler = e => {
    if (e.target.name === 'file') {
      setFormData({ ...formData, file: e.target.files[0] });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      setButtonClicked(true);
      alert('Mail Sent Successfully');
      const formDataToSend = new FormData();
      formDataToSend.append('fname', fname);
      formDataToSend.append('lname', lname);
      formDataToSend.append('email', email);
      formDataToSend.append('message', message);
      formDataToSend.append('file', file);

      const response = await axios.post('http://localhost:3001/api/form', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log('Response:', response.data); 
      window.location.reload();
      setFormData({
        fname: '',
        lname: '',
        email: '',
        message: '',
        file: null
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to send mail'); 
    }finally {
      setButtonClicked(false); // Reset button state
    }
  };

  return (
    <div id='contact'>
        <h1>Contact Us</h1>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
            <input type='text' placeholder='First Name' name='fname'  value={fname} onChange={changeHandler} required/>
            <input type='text' placeholder='Last Name' name='lname' value={lname} onChange={changeHandler} required/>
            <input type='email' placeholder='Enter E-Mail id' name='email' value={email} onChange={changeHandler} required/>
            <textarea placeholder='Write Here...' name='message' value={message} onChange={changeHandler} ></textarea>
            <input type='file' name='file' onChange={changeHandler} /> 
            <input type='Submit' value='Send' className={buttonClicked ? 'clicked' : ''}/>

        </form>

    </div>
  )
};

export default Contact