import React from 'react';
import Servicebox from './Servicebox';

const Services = () => {
  return (
    <div id='features'>
        <h1>Services</h1>
        <div className='a-container'>
            <Servicebox title="Permanent Staffing" para="When your company faces changes or special projects, EpicAmerica's contract staffing solutions bring in skilled professionals to support your business. Whether it's a short-term task or a large project, our proven process ensures a quick match with the right people possessing the skills your organization needs. We handle everything from recruiting to satisfaction measurement, specializing in IT with experts in areas like Cloud Computing, Salesforce, D365, Automation and programming languages."/>
            <Servicebox title="Contract Staffing" para="We manage the entire hiring process, starting from talent acquisition to providing guidance on securing the best permanent hires. EpicAmerica is a trusted agency for various businesses, offering recruitment services ranging from standard hires to large-scale staffing needs."/>
            <Servicebox title="Employee Training" para="At EpicAmerica, our focus is on training employees to enhance their success. Through job training programs, including upskilling and reskilling paths, we identify and provide the best-suited individuals for your business, regardless of the industry."/>
            <Servicebox title="Business Outsourcing" para="A trending outsourcing company, delivers flexible and cost-effective solutions tailored to your business requirements. Our outsourcing services enhance productivity, speed, and scalability while reducing costs and ensuring compliance. We manage HR outsourcing, recruitment process outsourcing, payroll services, and more."/>
        </div>
    </div>
  )
}

export default Services