import React from 'react'

const Header = () => {
  return (
    <div id='main'>
      <div className='header-heading'>
            <h2>EPIC AMERICAN</h2>
            <h1><span>IT Solutions </span></h1>
            <p className='details'> Empowering Progress, Epic Solutions: Unleashing America's IT Potential.</p>
            <div className='header-btns'>
              <a href='#contact' className='header-btn'> Contact Us</a>
            </div>
      </div>
    </div>
  )
}

export default Header;