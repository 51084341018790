import React from 'react'

const Footer = () => {
  return (
    <div className='footercss'>
        <div className='bottom'>
            <div>
                <h4>Address :</h4>
                <a>EPIC AMERICA'S IT Solutions</a>
                <a>5900 balcones Drive, Suite 100,</a>
                <a>Austin, TX - 78731, USA</a>
                <a>info@epicitamerican.com</a>
            </div>

            <div>
                <h4>Quick Links :</h4>
                <a href="#main">Home</a>
                <a href="#aboutus">About Us</a>
                <a href="#portfolioid">Portfolio</a>
                <a href="#features">Services</a>
            </div>

            <div>
                <h4>Services :</h4>
                <a >Permanent Staffing</a>
                <a >Contract Staffing</a>
                <a >Employee Training</a>
                <a >Business Outsourcing</a>
            </div>

            <div>
                <h4>Portfolio :</h4>
                <a>Project Management</a>
                <a>Business Analysis</a>
                <a>Strategic Planning</a>
                <a>Data-Driven Decision-Making</a>
                <a>Collaborative Leadership</a>
                <a>Continuous Improvement</a>
            </div>
          
        </div>
        <div className='bottomf'>
            <p>Copyright © 2024 - EPIC AMERICA'S IT Solutions. - All Rights Reserved.</p>
        </div>

    </div>
  )
}

export default Footer