import React from 'react';
const Aboutus = () => {
  return (
    
    <div id='aboutus'>
        <div className='aboutusp'>
          <h1> About Us</h1>
          <p>Welcome to EPIC AMERICAN IT Solutions, your premier destination for cutting-edge IT software consulting solutions. We specialize in providing innovative and tailored software consulting services to businesses of all sizes, helping them leverage technology to achieve their strategic objectives and stay ahead in today's dynamic digital landscape. At EPIC AMERICAN IT Solutions, we are empowering enterprises to expand their reach and secure a competitive edge by embracing digital transformation.</p>
          <h2>Why Choose Us?</h2>
          <p>
          <u><b>Expertise: </b></u>With years of industry experience and a track record of successful projects, our team of seasoned IT professionals brings unparalleled expertise and insights to every engagement, ensuring exceptional results and customer satisfaction.
          </p>
          <p>
          <u><b>Innovation: </b></u>We are committed to staying at the forefront of technological innovation, continuously exploring emerging trends and cutting-edge tools to deliver innovative solutions that drive business growth and transformation.
          </p>
          <p>
          <u><b>Client-Centric Approach: </b></u>We prioritize client satisfaction above all else, taking the time to understand your unique needs, challenges, and goals to deliver tailored solutions that exceed your expectations and deliver tangible value.
          </p>
          <p>
          <u><b>Collaborative Partnership: </b></u>We believe in fostering long-term collaborative partnerships with our clients, serving as trusted advisors and strategic partners in their digital journey, providing ongoing support, guidance, and expertise every step of the way.
          </p>
          <p>
          <u><b>Quality Assurance: </b></u>Our commitment to excellence extends to our rigorous quality assurance processes, ensuring that every solution we deliver meets the highest standards of quality, reliability, and performance, and delivers measurable business impact.
          </p>
        </div>
    </div>
  )
}

export default Aboutus