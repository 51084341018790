import React from "react";
import Navbar from "./Components/Navbar";
import Header from "./Components/Header";
import Portfolio from "./Components/Portfolio";
import Services from "./Components/Services";
import Aboutus from "./Components/Aboutus";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Header />
      <Aboutus />
      <Portfolio />
      <Services />
      <Contact />
      <Footer />
    </div>

  );
}

export default App;
